import React from "react";
import { Box } from "components/style/layoutStyle";
import ResponsiveMedia from "utils/responsive_media";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { showChannelTalk } from "hooks/useChannelIO";

const OnPremiseTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.72px;
    text-align: center;
    color: #1e1e1e;

    margin-bottom: 13px;

    ${({ isMobile }) => isMobile && `
        font-size: 22px;
    `};
`;

const OnPremiseDesc = styled.div`
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.54px;
    text-align: center;
    color: #1e1e1e;

    margin-bottom: 21px;
    white-space: pre-line;

    ${({ isMobile }) => isMobile && `
        font-size: 14px;
    `};
`;

const Button = styled.div`
    width: 134px;
    height: 36px;
    border-radius: 4px;
    background-color: #713838;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.42px;
    text-align: center;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    ${({ isMobile }) => isMobile && `margin: 0; margin-top: 24px;`}

    ${({ theme }) => theme.language === "en" && `font-size: 16px`};
`;

const OnPremiseBox = () => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <>
            <Box
                gap={"0px"}
                justifycontent={"center"}
                padding={isMobile ? "44px 20px" : "42px 0px"}
                bgColor={isMobile ? "#f8f8f8" : "#fff"}
                margin={isMobile ? "8px" : "40px 0 0 0"}
                maxWidth={"auto"}
                flexDirection={"column"}
                radius={isMobile ? 0 : "20px"}
            >
                <OnPremiseTitle isMobile={isMobile}>{t("PRICING_ONPREMISE_BOX_TITLE")}</OnPremiseTitle>
                <OnPremiseDesc isMobile={isMobile}>{t(isMobile ? "PRICING_ONPREMISE_BOX_MOBILE_DESC" : "PRICING_ONPREMISE_BOX_DESC")}</OnPremiseDesc>
                <Button onClick={() => showChannelTalk()}>{t("PRICING_ONPREMISE_BOX_BTN_TEXT")}</Button>
            </Box>
        </>
    );
};

export default OnPremiseBox;
