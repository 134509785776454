import React, { useState, useEffect, useRef, useContext } from "react";
import ResponsiveMedia from "utils/responsive_media";
import { RefWrapper } from "./style";
import { Container, Box } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import { ColoredBox } from "../parts/videoTitle/style";
import TopServiceButton from "./topServiceButton";
import PlanCard from "./planCard";
import PlanTable from "./planTable";
import PlanFaq from "./planFaq";
import BulkTable from "./bulkTable";
import YearTable from "./yearTable";
import CustomContent from "./cuostmContent";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";
import OnPremiseBox from "./onPremiseBox";

const Pricing = ({ title, planCard, planTable, planFaq, planFaqData }) => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const { isKo } = useContext(LanguageContext);

    const [wrapHeight, setWrapHeight] = useState(null);

    const option = {};
    let wrapRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entry) => {
            if (entry[0].isIntersecting) {
                setWrapHeight(entry[0].target.scrollHeight);
            }
        }, option);

        if (wrapRef.current) {
            observer.observe(wrapRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <RefWrapper ref={wrapRef}>
                <Container height={"auto"} alignitems={"center"} justifycontent={"center"} flexDirection={"column"}>
                    <ColoredBox height={isMobile === "true" ? (i18n.language === "ko" ? "200px" : "180px") : i18n.language === "ko" ? "1274px" : "1314px"} bgColor={"#faf7ef"} />
                    <Box margin={isMobile === "true" ? "45px 0" : i18n.language === "ko" ? "100px 0 52px" : "105px 0 52px"} flexDirection={"column"} maxWidth={"1280px"}>
                        <TextBold type={isMobile === "true" ? "28" : isKo ? "42" : "44"} textAlign={"center"} color={"#1e1e1e"} margin={isMobile === "true" ? "0 0 12px" : "0 0 16px"}>
                            {t(isMobile ? title.title_mobile : title.title)}
                        </TextBold>
                        <TextNormal
                            type={isMobile === "true" ? "16" : isKo ? "20" : "24"}
                            textAlign={"center"}
                            color={"#1e1e1e"}
                            padding={isMobile === "true" ? "0 0 52px" : "0 0 32px 0"}
                            dangerouslySetInnerHTML={{ __html: isMobile ? t(title.mobileSubtitle) : t(title.subtitle) }}
                        />
                        {!isMobile && <TopServiceButton />}
                        <PlanCard data={planCard} />
                        <OnPremiseBox />
                    </Box>
                </Container>
            </RefWrapper>
            <BulkTable />
            <YearTable />
            <CustomContent />
            <PlanTable data={planTable} wrapHeight={wrapHeight} />
            {/* <PlanFCTBanner /> */}
            <PlanFaq title={planFaq} data={planFaqData} />
        </>
    );
};

export default Pricing;
